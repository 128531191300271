<template>
  <div>
    <div class="container">
      <template v-if="!isOrderList">
        <div class="top-tabs">
          <span
            :class="[tabValue === 'item' ? 'active' : '']"
            @click="tabValue = 'item'"
            >{{ $t("myCard.titlePayAccount") }}</span
          >
          <span
            v-if="hasMycard2"
            :class="[tabValue === 'mycard2' ? 'active' : '']"
            @click="showCardTab('mycard2', 11)"
          >
            <img src="@/assets/images/mycardH5/card-mycard.png" alt="" />
          </span>
        </div>
        <template v-if="tabValue === 'item'">
          <div v-if="giftList.length === 0 && btnDisabled" class="empty">
            <img src="@/assets/images/mycardH5/empty.png" alt="" />
            <p>{{ $t("myCard.empty") }}</p>
          </div>
          <div v-else v-for="(each, index3) in giftList" :key="index3">
            <h3 class="sec-title">{{ each.title }}</h3>
            <div class="flex" style="justify-content: left">
              <template v-if="!isKr">
                <span
                  v-for="(item, index4) in each.arr"
                  :key="index4"
                  :class="[
                    'check',
                    'card',
                    item.show === 0 ||
                    (item.expiration_time && item.expiration_time < 0)
                      ? 'disable'
                      : '',
                    chooseProduct.product_id === item.product_id
                      ? 'active'
                      : '',
                  ]"
                >
                  <span class="flex">
                    <span
                      class="extra"
                      v-if="item.extra_num && item.extra_num > 0"
                      >{{ $t("myCard.extraGift") }}: {{ item.extra_num }}</span
                    >
                    <span
                      class="stock"
                      v-if="item.current_stock && item.current_stock > 0"
                      >{{ $t("myCard.stock")
                      }}{{
                        item.current_stock && item.current_stock > 99
                          ? "99+"
                          : item.current_stock
                      }}</span
                    >
                  </span>
                  <span class="img">
                    <img
                      class="bg"
                      src="@/assets/images/mycard/gift_bg.png"
                      alt=""
                    />
                    <img
                      v-if="item.icon && item.icon != null"
                      class="gift"
                      :src="
                        'https://oversea-update.fungoglobal.com/cat_web/goods_icon_' +
                        item.icon +
                        '.png'
                      "
                      alt=""
                    />
                    <img
                      v-else
                      class="gift"
                      src="@/assets/images/mycard/gift_default.png"
                      alt=""
                    />
                  </span>
                  <span class="title">{{ item.product_subject }}</span>
                  <span class="price" @click="chooseGift(item)">
                    <img src="@/assets/images/mycardH5/trolley.png" alt="" />
                    {{ item.currency_code }}{{ item.price / 100 }}</span
                  >
                  <i v-if="item.expiration_time" class="time">{{
                    item.expiration_time > 0
                      ? $t("myCard.time") + ": " + formatTime(item)
                      : $t("myCard.timeout")
                  }}</i>
                </span>
              </template>
              <template v-else>
                <span
                  v-for="(item, index4) in each.arr"
                  :key="index4"
                  :class="[
                    'check',
                    'card',
                    'kr-card',
                    item.show === 0 ||
                    (item.expiration_time && item.expiration_time < 0)
                      ? 'disable'
                      : '',
                    chooseProduct.product_id === item.product_id && !pointNum
                      ? 'active'
                      : '',
                  ]"
                >
                  <span class="flex">
                    <span class="extra_gift_percent" v-if="item.web_icon">
                      <img
                        :src="
                          'https://kr-update.fungoglobal.com/cat_web/' +
                          item.web_icon +
                          '.png?'
                        "
                        alt=""
                      />
                    </span>

                    <span
                      class="extra"
                      v-if="item.extra_num && item.extra_num > 0"
                      >{{ $t("myCard.extraGift") }}: {{ item.extra_num }}</span
                    >
                    <span
                      class="stock"
                      v-if="item.current_stock && item.current_stock > 0"
                      >{{ $t("myCard.stock")
                      }}{{
                        item.current_stock && item.current_stock > 99
                          ? "99+"
                          : item.current_stock
                      }}</span
                    >
                  </span>
                  <span class="img">
                    <img
                      class="bg"
                      src="@/assets/images/mycard/gift_bg.png"
                      alt=""
                    />
                    <img
                      v-if="item.product_id && item.product_id != null"
                      class="gift"
                      :src="
                        'https://kr-update.fungoglobal.com/cat_web/goods_icon_' +
                        item.product_id +
                        '.png?2024'
                      "
                      alt=""
                    />
                    <img
                      v-else
                      class="gift"
                      src="@/assets/images/mycard/gift_default.png"
                      alt=""
                    />
                    <span class="kr-double" v-if="item.is_first === 1"
                      ><img src="@/assets/images/mycard/kr-double.png" alt=""
                    /></span>
                  </span>
                  <span class="title"
                    >{{ item.product_subject
                    }}{{ item.num ? "x" + item.num : "" }}</span
                  >
                  <span
                    class="kr_extra"
                    v-if="item.first_extra_num || item.extra_goods_num"
                  >
                    <span>{{ $t("myCard.extraSend") }}</span>
                    <img
                      :src="
                        'https://kr-update.fungoglobal.com/cat_web/goods_icon_' +
                        item.extra_goods_id +
                        '.png'
                      "
                      alt=""
                    />
                    <span
                      >x{{
                        item.is_first === 1
                          ? item.first_extra_num
                          : item.extra_goods_num
                      }}</span
                    >
                    <template
                      v-if="
                        item.web_goods_num &&
                        item.web_goods_id !== item.extra_goods_id
                      "
                    >
                      <img
                        :src="
                          'https://kr-update.fungoglobal.com/cat_web/goods_icon_' +
                          item.web_goods_id +
                          '.png'
                        "
                        alt=""
                      />
                      <span>x{{ item.web_goods_num }}</span>
                    </template>
                  </span>
                  <span class="price" @click="chooseGift(item)">
                    <img src="@/assets/images/mycardH5/trolley.png" alt="" />
                    {{ item.currency_code }}{{ item.price / 100 }}</span
                  >
                  <i v-if="item.expiration_time" class="time">{{
                    item.expiration_time > 0
                      ? $t("myCard.time") + ": " + formatTime(item)
                      : $t("myCard.timeout")
                  }}</i>
                </span>
              </template>

              <!--       自定义     -->
              <span
                v-if="isKr && hasCustom(each.arr)"
                :class="['check', 'card', 'kr-card', pointNum ? 'active' : '']"
                @click="setPoint()"
              >
                <span class="extra_gift_percent">
                  <span>+{{ pointCustom.addition_num * 100 }}%</span>
                </span>
                <span class="img">
                  <img
                    class="bg"
                    src="@/assets/images/mycard/gift_bg.png"
                    alt=""
                  />
                  <img
                    class="gift"
                    src="https://kr-update.fungoglobal.com/cat_web/goods_icon_custom3000001.png"
                    alt=""
                  />
                </span>
                <span class="title">{{ $t("myCard.extraTitle") }}</span>
                <span class="kr_extra">
                  <template v-if="pointNum">
                    <img
                      src="https://kr-update.fungoglobal.com/cat_web/goods_icon_3000001.png"
                      alt=""
                    />
                    <em>x{{ pointNum }}</em>
                    <template v-if="pointExtraNum">
                      <img
                        src="https://kr-update.fungoglobal.com/cat_web/goods_icon_3000001.png"
                        alt=""
                      />
                      <em>x{{ pointExtraNum }}</em>
                    </template>
                  </template>
                  <template v-else>
                    <img
                      src="https://kr-update.fungoglobal.com/cat_web/goods_icon_3000001.png"
                      alt=""
                    />
                    <em>{{ $t("myCard.otherNum") }}</em>
                  </template>
                </span>
                <span class="price">
                  <img src="@/assets/images/mycardH5/trolley.png" alt="" />{{
                    pointCustom.currency_code
                  }}{{ pointPrice ? pointPrice : "_" }}
                </span>
              </span>
            </div>
          </div>
        </template>
        <template v-if="tabValue === 'mycard2'">
          <div class="sec-title">
            {{ $t("myCard.titlePayList") }}
          </div>
          <div class="table">
            <table>
              <thead>
                <tr>
                  <th>{{ $t("myCard.myCardPoint") }}</th>
                  <th>{{ $t("myCard.myCardGiftNum") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index2) in pointList" :key="index2">
                  <td>{{ item.point }}{{ $t("myCard.point") }}</td>
                  <td>{{ item.num }}</td>
                </tr>
              </tbody>
            </table>
            <div class="pay-card">
              <div class="flex">
                <span>{{ $t("myCard.cardNum") }}：</span>
                <input
                  type="text"
                  v-model="cardId"
                  :placeholder="$t('myCard.placeholderCardNum')"
                />
              </div>
              <div class="flex">
                <span>{{ $t("myCard.cardPass") }}：</span>
                <input
                  type="text"
                  v-model="cardPw"
                  :placeholder="$t('myCard.placeholderCardPass')"
                />
              </div>
            </div>
          </div>
          <a
            :class="['login-btn', disabled ? 'disabled' : '']"
            v-if="btnDisabled"
            @click="showLogin"
            >{{ $t("myCard.change") }}</a
          >
          <a
            :class="['login-btn', disabled ? 'disabled' : '']"
            v-else
            @click="submitCard()"
            >{{ $t("myCard.change") }}</a
          >
        </template>

        <h3>
          {{ $t("payRule.title") }}
        </h3>
        <div class="pay_tips">
          <p>{{ $t("payRule.text1") }}</p>
          <p>{{ $t("payRule.text2") }}</p>
          <p>{{ $t("payRule.text3") }}</p>
          <p>{{ $t("payRule.text4") }}</p>
          <p>{{ $t("payRule.text5") }}</p>
          <p>{{ $t("payRule.text6") }}</p>
        </div>
        <!--    -->

        <toast :message="message" v-if="message.length > 0"></toast>
        <loading v-if="showLoading"></loading>
        <div v-if="showPayStatus" class="fix-bg">
          <div class="choose-status" v-if="payStatus === -99">
            <p>{{ $t("myCard.pay1") }}</p>
            <div class="flex">
              <span @click="closeStatus">{{ $t("toast.comfirmBtn4") }}</span>
              <span class="active" @click="payQuery">{{
                $t("myCard.pay2")
              }}</span>
            </div>
          </div>
          <template v-else>
            <div class="success" v-if="payStatus === 2 || payStatus === 4">
              <img src="@/assets/images/mycard/success.png" alt="" />
              <h4>{{ $t("myCard.pay2") }}</h4>
              <p>{{ $t("myCard.pay5") }}</p>
              <div class="btn" @click="closeStatus">
                {{ $t("toast.comfirmBtn2") }}
              </div>
            </div>
            <div class="fail" v-else>
              <img src="@/assets/images/mycard/fail.png" alt="" />
              <h4>{{ $t("myCard.pay3") }}</h4>
              <p>{{ $t("myCard.pay4") }}</p>
              <div class="btn" @click="closeStatus">
                {{ $t("toast.comfirmBtn2") }}
              </div>
            </div>
          </template>
        </div>

        <div class="full" v-show="showAds && adsList.length > 0">
          <div class="full_main">
            <div class="swiper">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in adsList"
                  :key="index"
                >
                  <img :src="item.url" alt="" class="full_bg" />
                </div>
              </div>
              <div class="swiper-pagination" v-if="adsList.length > 1"></div>
            </div>

            <img
              src="@/assets/images/mycard/ad-close.png"
              alt=""
              class="full_close"
              @click="closeAds"
            />
          </div>
        </div>

        <!--  自定义弹窗  -->
        <div class="point-modal" v-show="showPoints">
          <div class="point-main">
            <div class="point-close" @click="closePointModal">
              <img src="@/assets/images/close.png" alt="" />
            </div>
            <div class="point-title">{{ $t("myCard.inputNum") }}</div>
            <input
              type="text"
              :placeholder="$t('myCard.inputNum')"
              v-model.number="pointNum"
              v-on:input="inputPoint"
              oninput="value=value.replace(/[^0-9.]/g,'')"
            />
            <p :class="pointError ? 'error' : ''">
              {{ $t("myCard.extraTips") }}
            </p>
            <div class="point-btn" @click="submitPoint">
              {{ $t("toast.comfirmBtn2") }}
            </div>
          </div>
        </div>
      </template>

      <div class="popup" v-if="showPop" @click="showPop = false">
        <div class="popup-main" @click.stop="showPop = true">
          <div class="popup-title">
            <div class="back" @click.stop="showPop = false">
              <img src="@/assets/images/mycard/arrow-down.png" alt="" />
            </div>
            <span>{{ $t("myCard.chooseCoupon") }}</span>
            <span class="exchange-btn" @click="showExchange = true"
              >{{ $t("myCard.exchangeCoupon") }}</span
            >
          </div>
          <div class="coupon-list" v-if="couponList.length > 0">
            <div
              v-for="(item, index) in couponList"
              :key="index"
              class="each flex"
            >
              <p
                v-if="
                  item.max_discount_price &&
                  item.max_discount_price[couponCurrentCode]
                "
              >
                {{ $t("myCard.maxNum") }}
                {{ couponCurrentCode === "KRW" ? "₩" : couponCurrentCode
                }}{{ item.max_discount_price[couponCurrentCode] }}
              </p>
              <div>
                <div class="c-name">
                  <span>{{ item.name }} </span>
                  <div class="c-question" @click.stop="showCouponInfo(item)">
                    <img
                      src="@/assets/images/mycard/question-circle.png"
                      alt=""
                    />
                  </div>
                </div>
                <p
                  v-if="
                    item.price_condition &&
                    item.price_condition[couponCurrentCode]
                  "
                  class="min-num"
                >
                  {{
                    $t("myCard.useType", {
                      amount:
                        couponCurrentCode === "KRW"
                          ? "₩" + item.price_condition[couponCurrentCode]
                          : couponCurrentCode +
                            item.price_condition[couponCurrentCode],
                    })
                  }}
                </p>
                <p class="time">
                  {{ $t("myCard.expireAt")
                  }}{{ formatDate(item.expire_at, "yyyy-MM-dd hh:mm:ss") }}
                </p>
              </div>
              <div class="c-num">
                <p>
                  <em class="share-tag" v-if="item.can_share === 1">{{ $t("myCard.share") }}</em>
                  <i>{{ 100 - item.discount }}%</i>{{ $t("myCard.disCount") }}
                </p>
              </div>
            </div>
          </div>
          <div v-else class="empty">
            <img src="@/assets/images/mycardH5/coupon-empty.png" alt="" />
            <p>{{ $t("myCard.noCoupon") }}</p>
          </div>
          <div class="c-info" v-if="showInfo">
            <div class="c-main">
              <div class="c-close" @click="showInfo = false">
                <img src="@/assets/images/close.png" alt="" />
              </div>
              <div class="z-title">{{ chooseQuestion.name }}</div>
              <div class="c-content" v-html="chooseQuestion.content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="web-intro" v-if="isKr && !isOrderList">
      <div class="right">
        <div class="each mb">
          <img src="@/assets/images/mycard/intro-icon1.png" alt="" />
          <div class="text">
            <p>안전성</p>
            <span>공식 결제 센터</span>
          </div>
        </div>
        <div class="each mb">
          <img src="@/assets/images/mycard/intro-icon2.png" alt="" />
          <div class="text">
            <p>편의성</p>
            <span>구매 수량 설정</span>
          </div>
        </div>
        <div class="each">
          <img src="@/assets/images/mycard/intro-icon3.png" alt="" />
          <div class="text">
            <p>혜택</p>
            <span>푸짐한 아이템 추가 증정 및 할인쿠폰</span>
          </div>
        </div>
        <div class="each">
          <img src="@/assets/images/mycard/intro-icon4.png" alt="" />
          <div class="text">
            <p>고효율</p>
            <span>구매 후 빠른 지급</span>
          </div>
        </div>
      </div>
      <div class="left">
        <img src="@/assets/images/mycard/fundoll.png" alt="" />
        <div class="links" style="font-size: 16px">
          <a
            href="https://kr.catfantasygame.com/H5/privacypolicy.html?agreement_id=54&amp;section=2&amp;title=개인정보"
            target="_black"
            style="margin-right: 0"
            >이용 약관</a
          >
          <i style="margin: 0 10px; color: #fa8d9e">|</i>
          <a
            href="https://kr.catfantasygame.com/H5/privacypolicy.html?agreement_id=54&amp;section=3&amp;title=약관"
            target="_black"
            >개인정보 처리방침</a
          >
        </div>
        <p>FUNTOY GAMES PTE. LTD. All rights reserved.</p>
        <p>FUNDOLL GLOBAL LIMITED All rights reserved.</p>
        <p>
          FUNGO GLOBAL PTE.LTD. 주소: 60 PAYA LEBAR ROAD #11-01 PAYA LEBAR
          SQUARE SINGAPORE (409051)
        </p>
        <p>
          사업등록자번호: 20181552C 대표번호: 8730 8068/8660 2503 대표이름: Zhou
          Yongfeng
        </p>
      </div>
    </div>

    <!--  兑换优惠券  -->
    <exchange
      v-if="showExchange"
      :isH5="true"
      :couponCurrentCode="couponCurrentCode"
      @closeExchangeModal="closeExchangeModal"
      @showCouponInfo="showCouponInfo"
    ></exchange>
  </div>
</template>

<script>
import {
  getLocalStorage,
  getSessionStorage,
  removeLocalStorage,
  setLocalStorage,
} from "@/utils/stage";
import {
  myCardInGamePay,
  webItemList,
  gashPointOrder,
  payQuery,
  myCoupon,
} from "@/api/mycard";
import toast from "./toast";
import loading from "./loading";
import staticGiftList from "./giftList";
import Swiper from "swiper";
import Exchange from "@/views/MyCard/components/exchange";

export default {
  name: "payCard",
  components: {
    toast,
    loading,
    Exchange,
  },
  props: {
    btnDisabled: {
      default: false,
    },
    isOrderList: {
      default: false,
    },
    chooseRoleInfo: {},
  },
  computed: {
    disabled() {
      if (!this.btnDisabled) {
        if (
          this.payWay === "mycard2" ||
          this.payWay === "gash2" ||
          this.tabValue === "mycard"
        ) {
          return (
            !(this.cardId.length > 0 && this.cardPw.length > 0) ||
            !this.chooseRoleInfo.role_id
          );
        } else {
          return (
            (!this.chooseProduct.product_id && !this.pointNum) ||
            !this.chooseRoleInfo.role_id
          );
          // && this.payWay === "mycard1"
        }
      } else {
        if (this.payWay === "mycard2" || this.payWay === "gash2") {
          return !(this.cardId.length > 0 && this.cardPw.length > 0);
        } else {
          return !this.chooseProduct.product_id && !this.pointNum;
          // && this.payWay === "mycard1"
        }
      }
    },
    hasMycard2() {
      let flag = false;
      const payWays = JSON.parse(getSessionStorage("configInit")).pay_ways;
      payWays.forEach((item) => {
        if (item.type === "11") {
          flag = true;
        }
      });
      return flag;
    },
  },
  data() {
    return {
      payWayId: 0,
      payWay: "mycard1",
      giftList: [],
      pointList: [],
      showLoading: false,
      message: "",
      roleId: this.$route.query.roleId,
      chooseProduct: {},
      projectId: getLocalStorage("projectId"),
      cardId: "",
      cardPw: "",
      gashData: {},
      gashUrl: "",
      timer: null,
      isEn: this.$isEn,
      isKr: this.$isKr,
      isTw: this.$isTw,
      showPayStatus: false,
      out_trade_no: "",
      payStatus: -99,
      pg_code: "creditcard",
      showAds: !getLocalStorage("hasShowAds"),
      adPopup: "",
      adsList: [],
      showPoints: false,
      pointNum: "",
      pointPrice: "",
      pointExtraNum: "",
      pointError: false,
      pointCustom: {
        addition_num: "",
        currency_code: "",
        exchange_rate: "",
        max_num: "",
        min_num: "",
        pay_point: "",
      },
      couponList: [],
      chooseQuestion: {},
      showCouponModal: false,
      couponOrder: {},
      couponCurrentCode: "",
      tabValue: "item",
      showPop: false,
      showInfo: false,
      showExchange: false,
    };
  },
  created() {
    this.showLoading = false;
    this.chooseProduct = getLocalStorage("chooseProduct")
      ? JSON.parse(getLocalStorage("chooseProduct"))
      : {};
    this.pointPrice = Number(getLocalStorage("pointPrice")) || 0;
    this.pointNum = Number(getLocalStorage("pointNum")) || "";
    this.pointExtraNum = Number(getLocalStorage("pointExtraNum")) || 0;
    setTimeout(() => {
      if (getLocalStorage("projectId")) {
        this.webItemList();
      }
    }, 300);
  },
  mounted() {
    this.watchProjectId();
    setTimeout(() => {
      this.initSwiper();
    }, 500);
  },
  watch: {
    chooseRoleInfo(val) {
      if (val) {
        this.webItemList();
      }
    },
    payWay(val) {
      if (val) {
        this.webItemList();
      }
    },
  },
  methods: {
    closeExchangeModal() {
      this.showExchange = false;
      this.myCoupon();
    },
    showCardTab(val, paywayId) {
      this.tabValue = val;
      this.payWay = val;
      this.payWayId = paywayId;
    },
    formatDate(dateNum, fmt) {
      const date = new Date(Number(dateNum) * 1000);
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      let o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.padLeftZero(str)
          );
        }
      }
      return fmt;
    },
    padLeftZero(str) {
      return ("00" + str).substr(str.length);
    },
    hasCustom(list) {
      let flag = false;
      list.forEach((item) => {
        if (item.productType === 80) {
          flag = true;
        }
      });
      return flag;
    },
    showLogin() {
      this.$parent.showLogin = true;
    },
    initSwiper() {
      const mySwiper = new Swiper(".swiper", {
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        on: {
          slideChangeTransitionStart: function () {
            // alert(this.activeIndex);
          },
        },
      });
    },
    closeAds() {
      this.showAds = false;
      setLocalStorage("hasShowAds", true);
    },
    watchProjectId() {
      const _this = this;
      window.addEventListener("setItemEvent", function (e) {
        if (e.key === "projectId") {
          _this.webItemList(e.newValue);
        }
      });
    },
    chooseGift(item) {
      if (this.btnDisabled) {
        this.showLogin();
      } else {
        if (item.show !== 0) {
          this.closePointModal();
          this.chooseProduct = Object.assign({}, item);
          setLocalStorage("chooseProduct", JSON.stringify(this.chooseProduct));
          this.$router.push("/orderPay");
        }
      }
    },
    showCouponPop() {
      this.showPop = true;
    },
    setPoint() {
      this.showPoints = true;
    },
    closePointModal() {
      this.showPoints = false;
      this.pointNum = "";
      this.pointPrice = "";
      this.pointExtraNum = "";
      this.pointError = false;
      setLocalStorage("pointNum", "");
      setLocalStorage("pointPrice", "");
      setLocalStorage("pointNum", "");
      setLocalStorage("pointExtraNum", "");
      setLocalStorage("pointExtraNum", "");
    },
    inputPoint() {
      if (
        this.pointNum < this.pointCustom.min_num ||
        this.pointNum == this.pointCustom.min_num ||
        this.pointNum > this.pointCustom.max_num ||
        this.pointNum % 10 !== 0
      ) {
        this.pointError = true;
      } else {
        this.pointError = false;
      }
    },
    submitPoint() {
      if (this.pointError) {
        return;
      }
      if (this.btnDisabled) {
        this.showLogin();
        return;
      }
      this.pointPrice = Math.floor(
        this.pointNum * this.pointCustom.exchange_rate
      );
      this.pointExtraNum = Math.ceil(
        this.pointNum * this.pointCustom.addition_num
      );
      setLocalStorage("pointPrice", this.pointPrice);
      setLocalStorage("pointNum", this.pointNum);
      setLocalStorage("pointExtraNum", this.pointExtraNum);
      setLocalStorage("chooseProduct", "");
      this.showPoints = false;
      this.$router.push("/orderPay");
    },
    myCoupon() {
      this.showLoading = true;
      this.couponList = [];
      myCoupon()
        .then((res) => {
          this.couponList = res.data.coupons;
          this.showLoading = false;
        })
        .catch(() => {
          this.showLoading = false;
        });
    },
    showCouponInfo(item) {
      item.content = item.content.replace(/\n/g, "</br>");
      this.chooseQuestion = item;
      this.showInfo = true;
    },
    webItemList(newId) {
      if (!this.btnDisabled && !this.chooseRoleInfo.role_id) {
        return;
      }
      let project_id = "";
      if (newId) {
        project_id = newId;
      } else {
        if (getLocalStorage("projectId")) {
          project_id = getLocalStorage("projectId");
        } else {
          project_id = this.chooseRoleInfo.project_id;
        }
      }
      this.showLoading = true;
      webItemList({
        // game_app_id: this.gameAppId,
        role_id:
          this.chooseRoleInfo && this.chooseRoleInfo.role_id
            ? this.chooseRoleInfo.role_id
            : "",
        project_id: project_id,
        payway_id: this.payWayId,
      })
        .then((res) => {
          if (res.data.ad.image) {
            this.adPopup = res.data.ad.adPopup;
            let lang = "ko-kr";
            if (this.isKr) {
              lang = "ko-kr";
            } else if (this.isEn) {
              lang = "en-us";
            } else {
              lang = "zh-tw";
            }
            if (res.data.ad.image[lang] && res.data.ad.image[lang].length) {
              this.adsList = res.data.ad.image[lang];
            } else {
              this.showAds = false;
            }
          } else {
            this.showAds = false;
          }
          if (res.data.custom != null) {
            this.pointCustom = res.data.custom;
            if (
              this.pointCustom.currency_code &&
              this.pointCustom.currency_code === "KRW"
            ) {
              this.pointCustom.currency_code = "₩";
            }
          }
          this.couponCurrentCode = res.data.currency_code || "";
          setLocalStorage("couponCurrentCode", this.couponCurrentCode);
          this.giftList = [];
          const titleArr = [
            this.$t("myCard.secTitle1"),
            this.$t("myCard.secTitle2"),
            this.$t("myCard.secTitle3"),
            this.$t("myCard.secTitle4"),
          ];
          Object.values(res.data.common).forEach((list, index) => {
            let obj = {
              title: titleArr[index],
              arr: [],
            };
            list.forEach((inner) => {
              inner.products.forEach((item) => {
                item.productType = inner.productType;
                if (item.currency_code && item.currency_code === "KRW") {
                  item.currency_code = "₩";
                }
                if (item.expiration_time) {
                  if (item.expiration_time > 0) {
                    if (
                      item.current_stock &&
                      item.current_stock < 1 &&
                      item.current_stock !== -1
                    ) {
                      item.current_stock = "0";
                      item.show = 0;
                    } else {
                      item.show = 1;
                    }
                    item.hours = Math.floor(item.expiration_time / 3600);
                    item.minute = Math.floor(
                      Math.floor(item.expiration_time % 3600) / 60
                    );
                    item.second = item.expiration_time % 60;
                  } else {
                    item.show = 0;
                  }
                } else {
                  item.show = 1;
                }
                // if (item.product_subject === "" || !item.product_subject) {
                // if (!this.isKr) {
                staticGiftList.forEach((gift) => {
                  if (gift.product_id == item.product_id) {
                    if (this.isEn) {
                      item.product_subject = gift.product_subject_en;
                    } else if (this.isKr) {
                      item.product_subject = gift.product_subject_kr;
                    } else {
                      item.product_subject = gift.product_subject;
                    }
                    if (!gift.icon) {
                      item.icon = gift.product_id;
                    }
                  }
                });
                // }
                // productType = 305 or 306 名字写死
                if (item.productType === 305) {
                  item.product_subject = "限時秒殺禮包";
                } else if (item.productType === 306) {
                  item.product_subject = "金喵賜福禮包";
                }
                // }
              });
              obj.arr.push(...inner.products);
            });
            this.giftList.push(obj);
          });
          setTimeout(() => {
            this.countDown();
          }, 100);
          this.pointList = res.data.point;
          this.showLoading = false;
          this.chooseProduct = {};
          removeLocalStorage("chooseProduct");
          removeLocalStorage("pointPrice");
          removeLocalStorage("pointNum");
          removeLocalStorage("pointExtraNum");
        })
        .catch(() => {
          this.showLoading = false;
        });
    },
    payQuery() {
      payQuery({ out_trade_no: this.out_trade_no }).then((res) => {
        this.payStatus = res.data.status || 0;
        if (this.payStatus === 2 || this.payStatus === 4) {
          this.webItemList();
          this.addPayTime();
          this.chooseProduct = {};
          this.cardId = "";
          this.cardPw = "";
          removeLocalStorage("chooseProduct");
          removeLocalStorage("pointPrice");
          removeLocalStorage("pointNum");
          removeLocalStorage("pointExtraNum");
        }
      });
    },
    addPayTime() {
      let arr = getLocalStorage("payTime")
        ? JSON.parse(getLocalStorage("payTime"))
        : "" || [];
      let flag = true;
      arr.forEach((item) => {
        if (
          item.open_id === JSON.parse(getLocalStorage("accountStorage")).open_id
        ) {
          item.payTime = Date.parse(new Date());
          item.role_id = this.chooseRoleInfo.role_id;
          flag = false;
        }
      });
      if (flag) {
        let obj = {
          open_id: JSON.parse(getLocalStorage("accountStorage")).open_id,
          payTime: Date.parse(new Date()),
          role_id: this.chooseRoleInfo.role_id,
        };
        arr.push(obj);
      }
      setLocalStorage("payTime", JSON.stringify(arr));
    },
    closeStatus() {
      this.webItemList();
      this.showPayStatus = false;
      this.payStatus = -99;
    },
    submitCard() {
      if (!this.disabled) {
        this.showLoading = true;
        if (this.payWay === "gash2") {
          let obj1 = {
            // access_token: "0000018704763bd179ee6e98065859813",
            // app_id: "05eb00895c7cdda96dc3974300fc03cc",
            game_app_id: this.chooseRoleInfo.game_app_id,
            server_id: this.chooseRoleInfo.server_id,
            role_id: this.chooseRoleInfo.role_id,
            success_url: window.location.origin + "/mycard/final?final=1",
            failed_url: window.location.origin + "/mycard/final?final=0&msg=%s",
            mobile: 1, // 0:PC 1:H5
          };
          gashPointOrder(obj1)
            .then((res) => {
              this.showLoading = false;
              this.showPayStatus = true;
              if (res.data.transaction_url) {
                this.gashUrl = res.data.transaction_url;
                this.gashData = res.data.auth_code;
                let formData = `<form name="form1" id="form1" action=${this.gashUrl} method="post">
              <input type="hidden" name="data" value=${this.gashData}>
              <input type="submit" value="send">
            </form>`;

                let newWindow = window.open("", "catPay");
                newWindow.document.write("<html><head></head><body>");
                let div = newWindow.document.createElement("div");
                div.innerHTML = formData;
                newWindow.document.body.appendChild(div);
                div.style.display = "none";
                newWindow.document.form1.submit();
              } else {
                this.showLoading = false;
                this.message = this.$t("myCard.failed");
              }
            })
            .catch((error) => {
              this.showLoading = false;
              this.message = error.message;
            });
        } else {
          let obj = {
            card_id: this.cardId,
            card_pw: this.cardPw,
            server_id: this.chooseRoleInfo.server_id,
            role_id: this.chooseRoleInfo.role_id,
            game_app_id: this.chooseRoleInfo.game_app_id,
            success_url: window.location.origin + "/mycard/final?final=1",
            failed_url: window.location.origin + "/mycard/final?final=0&msg=%s",
            mobile: 1, // 0:PC 1:H5
          };
          if (this.cardPw.length > 0 && this.cardId.length > 0) {
            myCardInGamePay(obj)
              .then((res) => {
                this.showPayStatus = true;
                if (res.data.success === 1) {
                  this.payStatus = 2;
                } else {
                  this.payStatus = 5;
                }
                this.showLoading = false;
                let href =
                  window.location.origin +
                  "/mycard/final?final=" +
                  res.data.success +
                  "&msg=" +
                  res.data.msg;
                window.open(href, "catPay");
                // this.$router.push({
                //   path: "/mycard/final",
                //   query: { final: res.data.success, msg: res.data.msg },
                // });
              })
              .catch((error) => {
                this.showLoading = false;
                this.message = error.message;
              });
          } else {
            this.showLoading = false;
          }
        }
      }
    },
    formatTime(item) {
      let hoursStr = "";
      let minuteStr = "";
      let secondStr = "";
      hoursStr =
        item.hours.toString().length === 1 ? `0${item.hours}` : item.hours;
      minuteStr =
        item.minute.toString().length === 1 ? `0${item.minute}` : item.minute;
      secondStr =
        item.second.toString().length === 1 ? `0${item.second}` : item.second;
      if (item.hours / 24 > 2) {
        return Math.floor(item.hours / 24) + this.$t("myCard.days");
      } else if (item.hours / 24 > 1) {
        return Math.floor(item.hours / 24) + this.$t("myCard.days");
      } else {
        return hoursStr + ":" + minuteStr + ":" + secondStr;
      }
    },
    countDown() {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.giftList.forEach((list) => {
          list.arr.forEach((item) => {
            if (item.expiration_time && item.expiration_time > 0) {
              if (item.hours === 0) {
                if (item.minute !== 0 && item.second === 0) {
                  item.second = 59;
                  item.minute -= 1;
                } else if (item.minute === 0 && item.second === 0) {
                  item.second = 0;
                  item.show = 0;
                  clearInterval(this.timer);
                  if (this.chooseProduct.show === 0) {
                    this.chooseProduct = {};
                  }
                } else {
                  item.second -= 1;
                }
              } else {
                if (item.minute !== 0 && item.second === 0) {
                  item.second = 59;
                  item.minute -= 1;
                } else if (item.minute === 0 && item.second === 0) {
                  item.hours -= 1;
                  item.minute = 59;
                  item.second = 59;
                } else {
                  item.second -= 1;
                }
              }
              this.$forceUpdate();
            }
          });
        });
      }, 1000);
    },
  },
};
</script>
